import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '@components/layout'
import { Slice } from '@components/slice'
import {
  ContentBox,
  DescriptionBox,
  StyledContainerBox,
  ProjectStyledLink,
  TitleBox,
} from '@slices/hero-with-image/style'
import { RichText } from 'prismic-reactjs'
import { Box, Flex } from '@rebass/grid'
import { DetailsList } from '@slices/content-with-image/style'
import { TrailFadeUp } from '@utils/animations'

type Props = {
  data: any
  pageContext: any
}

const ProjectsPost: React.FC<Props> = props => {
  const metaData = props.data?.meta_data
  const seo = props.data?.seo
  let header = props.data?.header
  const main = props.data?.main?.data
  const footer = props.data?.footer
  const theme = props.pageContext?.theme
  const slices = main?.body

  const { title, summary, details, relationship } = main
  header = { ...header, socialLinks: footer?.data?.social_links }

  const contentElement = (
    <StyledContainerBox as="section">
      <TrailFadeUp>
        {relationship?.uid && (
          <Box mb={'1.5rem'}>
            <ProjectStyledLink to={relationship?.url}>
              {relationship.uid}
            </ProjectStyledLink>
          </Box>
        )}
        <TitleBox mb={'3.5rem'}>
          <RichText render={title.raw} />
        </TitleBox>

        <ContentBox>
          <Flex flexWrap="wrap">
            <DescriptionBox width={[1, 1, 1, 3 / 5]} pr="2rem">
              <RichText render={summary?.raw} />
            </DescriptionBox>
            <DescriptionBox width={[1, 1, 1, 2 / 5]} pl={['0', , , '2rem']}>
              <DetailsList>
                {details.map((item, index) => (
                  <li key={`detail-${index}`}>
                    <p>
                      <span>{item.label}</span>
                      <span>{item.value}</span>
                    </p>
                  </li>
                ))}
              </DetailsList>
            </DescriptionBox>
          </Flex>
        </ContentBox>
      </TrailFadeUp>
    </StyledContainerBox>
  )

  return (
    <Layout
      seo={seo}
      header={header}
      footer={footer}
      theme={theme}
      activeMetaData={metaData}
    >
      {contentElement}
      <Slice allSlices={slices} />
    </Layout>
  )
}

export default ProjectsPost

export const pageQuery = graphql`
  query ProjectsPost($uid: String, $locale: String) {
    seo: prismicProjectPost(uid: { eq: $uid }, lang: { eq: $locale }) {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url(imgixParams: { width: 1200, height: 630 })
        }
      }
    }

    header: prismicHeader(lang: { eq: $locale }) {
      ...header
    }

    main: prismicProjectPost(uid: { eq: $uid }, lang: { eq: $locale }) {
      data {
        date
        title {
          raw
        }
        details {
          label
          value
        }
        summary {
          raw
        }
        relationship {
          url
          uid
        }
        body {
          ... on PrismicProjectPostDataBodyContent {
            id
            slice_type
            slice_label
            primary {
              content {
                raw
              }
            }
            items {
              content_in_column {
                raw
              }
            }
          }
          ... on PrismicProjectPostDataBodyNextPostLink {
            ...nextProjectLink
          }
        }
      }
    }

    footer: prismicFooter(lang: { eq: $locale }) {
      ...footer
    }
  }
`
